/* eslint-disable import/no-default-export */
import React from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import { Layout } from '../components/Layout';

import { PostList } from '../components/PostList/PostList';
import { TagList } from '../components/TagList';
import { IBlogPageContext } from '../interfaces/pageContext';
import { IBlogPostSummary, IAllWordpressTags } from '../interfaces/blog';
import { SelectedPosts } from '../components/SelectedPosts';
import { HeroBlogImage } from '../img/svg/HeroBlogImage';

type IBlogPosts = IBlogPostSummary & IBlogPageContext & IAllWordpressTags;

export default function IndexPage(props: IBlogPosts) {
  const { data } = props;
  const { edges: posts } = data.posts;
  const { edges: tagPosts } = data.tag;
  const { edges: tags } = data.allWordpressTag;

  return (
    <Layout>
      <Helmet title="Blog | swiatubezpieczen.com" />
      <section id="hero" className="section section--blog-hero-image">
        <div className="wrapper">
          <HeroBlogImage />
        </div>
      </section>

      <section id="newest" className="section section--carousel-newest">
        <div className="wrapper">
          <PostList posts={posts} title="Najnowsze" />
        </div>
      </section>

      <TagList tags={tags} />

      <SelectedPosts tags={tags} posts={posts} />

      <section className="section section--carousel-tag">
        <div className="wrapper">
          <PostList posts={tagPosts} title="OC/AC" />
        </div>
      </section>
    </Layout>
  );
}

export const pageQuery = graphql`
  query IndexQuery {
    posts: allWordpressPost(
      sort: { fields: date, order: DESC }
    ) {
      edges {
        node {
          ...PostListFields
        }
      }
    }
    tag: allWordpressPost(
        sort: { fields: date, order: DESC }
        filter: {tags: {elemMatch: {name: {eq: "oc"}}}}
    ) {
        edges {
            node {
                ...PostListFields
            }
        }
    }
    allWordpressTag(
        limit: 8
    ) {
        edges {
            node {
                ...TagListFields
            }
        }
    }
  }
`;
