import React from 'react';
import { Link } from 'gatsby';
import { INodePostSummary, INodeTagSummary, IPostSummary } from '../interfaces/blog';
import { coloredTags } from './TagList';

interface IProps {
  tags: INodeTagSummary[] | [];
  posts: INodePostSummary[];
}

interface IArticle {
  post: IPostSummary;
  postNumber: string;
}

const Article: React.FC<IArticle> = ({ post, postNumber }) => (
  <article className={`selected-post selected-post--${postNumber}`}>
    {post.tags && post.tags.length ?
      <span className={`selected-post__tag tag tag--${coloredTags.includes(post.tags[0].name) ? post.tags[0].name : 'default'} tag--font-white tag--uppercase`}>{post.tags[0].name}</span> :
      null}
    <Link className="selected-post__title" to={`/${post.slug}/`}>{post.title}</Link>
    <p className="selected-post__content">{post.excerpt}</p>
  </article>
);

export const SelectedPosts: React.FC<IProps> = ({ posts }) => {
  const randomPostsIndexes = [];

  while (randomPostsIndexes.length < 8) {
    const randomPostIndex = Math.floor(Math.random() * posts.length);
    if (randomPostsIndexes.indexOf(randomPostIndex) === -1) randomPostsIndexes.push(randomPostIndex);
  }

  const firstPost = posts[randomPostsIndexes[0]].node;
  const secondPost = posts[randomPostsIndexes[1]].node;
  const thirdPost = posts[randomPostsIndexes[2]].node;

  return (
    <section id="selected-posts" className="section section--selected-posts">
      <div className="wrapper">
        <div className="selected-posts-wrapper row row--gutters">
          <div className="selected-posts-wrapper__group row__large-8">
            <Article post={firstPost} postNumber="first" />
            <Article post={secondPost} postNumber="second" />
          </div>
          <div className="selected-posts-wrapper__group row__large-4">
            <Article post={thirdPost} postNumber="third" />
          </div>
        </div>
      </div>
    </section>
  );
};
