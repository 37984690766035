import React from 'react';
import { graphql, Link } from 'gatsby';
import { INodeTagSummary } from '../interfaces/blog';

export const coloredTags = ['oc', 'ac', 'assistance', 'nnw', 'kalkulator', 'mieszkanie', 'na-zycie', 'podroze'];

export const TagList: React.FC<{tags: INodeTagSummary[] | []}> = ({ tags }) => (
  <section id="tags" className="section section--tag-list tag-list">
    <div className="wrapper">
      <h3 className="tag-list__title heading-h3">Znajdź po tagach</h3>
      <div className="tag-list__wrapper">
        {
          tags && tags.length ? (
            (tags as INodeTagSummary[]).map(({ node: tag }) => (
              <Link className="tag-list__link" to={`/tags/${tag.slug}/`}>
                <span
                  className={`tag tag--uppercase tag--${coloredTags.includes(tag.slug) ? tag.slug : 'default'} `}
                >
                  {tag.name}
                </span>
              </Link>
            ))
          ) : null
        }
      </div>
    </div>
  </section>
);

export const pageQuery = graphql`
    fragment TagListFields on wordpress__TAG {
        id
        slug
        name
    }
`;
